.toast {
  background: #201e1e;
  color: #fff;
  border-radius: 8px !important;
  padding: 15px !important;
  display: flex;
  cursor: pointer;
  margin: 4px !important;
  justify-content: flex-start;
  align-content: center;
}

.toast-warn {
  background: #f1c40f;
  color: #fff;
  border-radius: 8px !important;
  padding: 15px !important;
  display: flex;
  cursor: pointer;
  margin: 4px !important;
  justify-content: flex-start;
  align-content: center;
}

.toast-success {
  background: #07bc0c;
  color: #fff;
  border-radius: 8px !important;
  padding: 15px !important;
  display: flex;
  cursor: pointer;
  margin: 4px !important;
  justify-content: flex-start;
  align-content: center;
}

.toast-error {
  background: #e74c3c;
  color: #fff;
  border-radius: 8px !important;
  padding: 15px !important;
  display: flex;
  cursor: pointer;
  margin: 4px !important;
  justify-content: flex-start;
  align-content: center;
}

.Toastify__close-button--light {
  color: #fff;
  opacity: 1;
}